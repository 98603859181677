import React from 'react';
import {  Card, CardHeader, CardBody, Table , Pagination, PaginationItem,  PaginationLink  } from "reactstrap";

class PaginatedTable extends React.Component {
  
  constructor(props) {
    super();
    this.state = {
      currentPage: props.currentPage,
      regsPerPage: props.regsPerPage,
      upperPageBound: props.upperPageBound,
      lowerPageBound: props.lowerPageBound,
      isPrevBtnActive: props.isPrevBtnActive,
      isNextBtnActive: props.isNextBtnActive,
      pageBound: props.pageBound
    };
    this.handleClick = this.handleClick.bind(this);
    this.btnDecrementClick = this.btnDecrementClick.bind(this);
    this.btnIncrementClick = this.btnIncrementClick.bind(this);
    this.btnNextClick = this.btnNextClick.bind(this);
    this.btnPrevClick = this.btnPrevClick.bind(this);
    this.setPrevAndNextBtnClass = this.setPrevAndNextBtnClass.bind(this);


  }
  handleClick(event) {
    let listid = Number(event.target.id);
    this.setState({
      currentPage: listid
    });
    this.setPrevAndNextBtnClass(listid);
  }
  setPrevAndNextBtnClass(listid) {
    let totalPage = Math.ceil(this.props.registros.length / this.state.regsPerPage); 
    this.setState({isNextBtnActive: 'disabled'});
    this.setState({isPrevBtnActive: 'disabled'});
    if(totalPage === listid && totalPage > 1){
        this.setState({isPrevBtnActive: ''});
    }
    else if(listid === 1 && totalPage > 1){
        this.setState({isNextBtnActive: ''});
    }
    else if(totalPage > 1){
        this.setState({isNextBtnActive: ''});
        this.setState({isPrevBtnActive: ''});
    }
}
  btnIncrementClick() {
      this.setState({upperPageBound: this.state.upperPageBound + this.state.pageBound});
      this.setState({lowerPageBound: this.state.lowerPageBound + this.state.pageBound});
      let listid = this.state.upperPageBound + 1;
      this.setState({ currentPage: listid});
      this.setPrevAndNextBtnClass(listid);
}
  btnDecrementClick() {
    this.setState({upperPageBound: this.state.upperPageBound - this.state.pageBound});
    this.setState({lowerPageBound: this.state.lowerPageBound - this.state.pageBound});
    let listid = this.state.upperPageBound - this.state.pageBound;
    this.setState({ currentPage: listid});
    this.setPrevAndNextBtnClass(listid);
}
btnPrevClick() {
    if((this.state.currentPage -1)%this.state.pageBound === 0 ){
        this.setState({upperPageBound: this.state.upperPageBound - this.state.pageBound});
        this.setState({lowerPageBound: this.state.lowerPageBound - this.state.pageBound});
    }
    let listid = this.state.currentPage - 1;
    this.setState({ currentPage : listid});
    this.setPrevAndNextBtnClass(listid);
}
btnNextClick() {
    if((this.state.currentPage +1) > this.state.upperPageBound ){
        this.setState({upperPageBound: this.state.upperPageBound + this.state.pageBound});
        this.setState({lowerPageBound: this.state.lowerPageBound + this.state.pageBound});
    }
    let listid = this.state.currentPage + 1;
    this.setState({ currentPage : listid});
    this.setPrevAndNextBtnClass(listid);
}


render() {
const { currentPage,regsPerPage, upperPageBound,lowerPageBound,isPrevBtnActive,isNextBtnActive } = this.state;
const {  registros, tableHeader, tableTitle, tableSubtitle} = this.props;

const indexOfLastReg = currentPage * regsPerPage;
const indexOfFirstReg = indexOfLastReg - regsPerPage;
const currentRegs = registros.slice(indexOfFirstReg, indexOfLastReg);



// Logic for displaying page numbers
const pageNumbers = [];
for (let i = 1; i <= Math.ceil(registros.length / regsPerPage); i++) {
  pageNumbers.push(i);
}

const renderPageNumbers = pageNumbers.map(number => {
    if(number === 1 && currentPage === 1){
        return(
          <PaginationItem  active={number === currentPage} key={number} >
          <PaginationLink 
            id={number}
            onClick={this.handleClick}>
            {number}
          </PaginationLink>
        </PaginationItem>
        )
    }
    else if((number < upperPageBound + 1) && number > lowerPageBound){
        return(
            <PaginationItem active={number=== currentPage} key={number} >
              <PaginationLink 
                id={number}
                onClick={this.handleClick}>
                {number}
              </PaginationLink>
            </PaginationItem>
        )
    }
    return null
});
let pageIncrementBtn = null;
if(pageNumbers.length > upperPageBound){
    pageIncrementBtn = 
    <PaginationItem key="pageIncrementBtn">
    <PaginationLink  
      onClick={this.btnIncrementClick}>
       &hellip;
    </PaginationLink>
  </PaginationItem>
}
let pageDecrementBtn = null;
if(lowerPageBound >= 1){
    pageDecrementBtn = 
    <PaginationItem key="btnDecrementClick">
    <PaginationLink  
      onClick={this.btnDecrementClick}>
      &hellip;
    </PaginationLink>
  </PaginationItem>
}
let renderPrevBtn = null;
if(isPrevBtnActive === 'disabled') {
    renderPrevBtn = 
    <PaginationItem  className={isPrevBtnActive} key="0" >
    <PaginationLink id="btnPrev"  >
    <span> Prev </span>
    </PaginationLink>
  </PaginationItem>
}
else{
    renderPrevBtn = 
    <PaginationItem  key="btnPrev">
    <PaginationLink className={isPrevBtnActive} id="btnPrev" onClick={this.btnPrevClick} >
    <span> Prev </span>
    </PaginationLink>
  </PaginationItem>
}
let renderNextBtn = null;
if(isNextBtnActive === 'disabled') {
    renderNextBtn = 
    <PaginationItem className={isNextBtnActive} key="btnNext"  >
    <PaginationLink id="btnNext"  >
    <span > Next </span>
    </PaginationLink>
  </PaginationItem>
}
else{
    renderNextBtn = 
    <PaginationItem className={isNextBtnActive} key="btnNext">
    <PaginationLink id="btnNext" onClick={this.btnNextClick}  >
    <span> Next </span>
    </PaginationLink>
  </PaginationItem>

}

const tableHeaders = tableHeader.map(( head , index ) => {
  return <th key={index}>{head}</th>
})

return (
  <div>
  <Card className="card-tasks">
    <CardHeader>
      <h6 className="title d-inline">{ tableTitle}</h6>
      <p className="card-category d-inline"> {tableSubtitle} </p>
    </CardHeader>
    <CardBody>
      <div>
        <Table>
          <tbody>
          <tr>{tableHeaders}</tr>
          {currentRegs}
          </tbody>
        </Table>
        <Pagination size="sm"  aria-label="Page navigation example">
        {renderPrevBtn}
        {pageDecrementBtn}
        {renderPageNumbers}
        {pageIncrementBtn}
        {renderNextBtn}
      </Pagination>
      </div>
    </CardBody>
  </Card>  
  </div>
  );
}
}




export default PaginatedTable
/*!

=========================================================
* Black Dashboard React v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

// reactstrap components
import { Container, Row, Nav, NavItem, NavLink , Link} from "reactstrap";

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <Container fluid>
          <Nav>
            <NavItem>
              <a href="https://www.santander.com.ar" target="_blank" className="nav-link">Banco Santander</a>
            </NavItem>
            <NavItem>
              <a href="https://www.afip.gob.ar" target="_blank" className="nav-link">Afip</a>
            </NavItem>
            <NavItem>
              <a href="https://ads.google.com" target="_blank" className="nav-link">Google Ads</a>
            </NavItem>
            <NavItem>
              <a href="https://www.donweb.com" target="_blank" className="nav-link">Donweb</a>
            </NavItem>
          </Nav>
          <div className="copyright">
            © {new Date().getFullYear()} 
            <a
              href="https://www.gallagherbros.com.ar"
              rel="noopener noreferrer"
              target="_blank"
            > { " " }
              GallagherBros
            </a>
          </div>
        </Container>
      </footer>
    );
  }
}

export default Footer;

import { combineReducers } from 'redux';
import ingresos from './ingresoReducer';
import clientes from './clienteReducer';
import notifications from './notifications';

export default combineReducers({
    ingresos: ingresos,
    clientes: clientes,
    notifications: notifications

});
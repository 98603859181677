import React from 'react';
import moment from 'moment';
import {  Button  } from "reactstrap";
import PropTypes from 'prop-types';

class IngresoRow extends React.Component {
  
  static propTypes = {
    ingreso: PropTypes.shape({
      fecha: PropTypes.string,
      razon_social: PropTypes.string,
      monto: PropTypes.number,
      concepto: PropTypes.string,
      id_ingreso: PropTypes.number.isRequired,
      destino: PropTypes.string,
      estado: PropTypes.string,
      tipo: PropTypes.string,
      comprobante: PropTypes.oneOf([0,1]),
    }).isRequired,
    isLite: PropTypes.bool,
    handleUpdate: PropTypes.func,
    onDelete: PropTypes.func,
    handleDelete: PropTypes.func
  };

  static defaultProps = {
    isLite: false
  };
/*
  constructor(props) {
    super(props);
  }
 */
  
  renderLite  = () =>  {
    const {  ingreso } = this.props;

    let destino_mostrar = '' ; 
    let comprobante_mostrar = '' ;
    let comprobante_texto = '' ;
    if (ingreso.destino==="Banco") {
      destino_mostrar =  <span><Button color="link" id={"tooltip_ingreso_bank_" + ingreso.id_ingreso }  type="button"  className="btn btn-round btn-icon"> <i className="tim-icons  icon-bank" /></Button></span>;
    } else {
      destino_mostrar = <span><Button color="link" id={"tooltip_ingreso_efectivo_" + ingreso.id_ingreso }   type="button"  className="btn btn-round btn-icon">  <i className="fas fa-money-bill-alt"></i></Button></span>;
    }
    if (ingreso.comprobante) {       
      comprobante_texto = 'Emitido';
      comprobante_mostrar = <span><Button color="link" id={"tooltip_ingreso_comprobante_" + ingreso.id_ingreso }  alt={comprobante_texto} type="button" className="btn btn-round btn-icon"> <i className="tim-icons  icon-paper"/></Button></span>;
    }else{
      comprobante_texto = 'Pendiente';
      comprobante_mostrar = <span><Button color="link" id={"tooltip_ingreso_comprobante_" + ingreso.id_ingreso }  alt={comprobante_texto} type="button" className="btn btn-round btn-icon"> <i className="tim-icons  icon-paper"/></Button></span>;

    }
  

    return (<tr key={ingreso.id_ingreso}>
      <td>
        <p className="title">{ingreso.razon_social}</p>
        <p className="text-muted">  ({ ingreso.concepto })  </p>
      </td>
      <td>  { ingreso.monto.toLocaleString('es-ar', { style: 'currency',  currency: 'ARS',  minimumFractionDigits: 2 }) } </td>
      <td>  {comprobante_mostrar}   {destino_mostrar}  </td>
    </tr>)
  }
  renderFull = () =>  {
    const {  ingreso , handleUpdate, handleDelete } = this.props;

    let destino_mostrar = '' ; 
    let comprobante_mostrar = '' ;
    let comprobante_texto = '' ;
    if (ingreso.destino==="Banco") {
      destino_mostrar =  <span><Button color="link" id={"tooltip_ingreso_bank_" + ingreso.id_ingreso }  type="button"  className="btn btn-round btn-icon"> <i className="tim-icons  icon-bank" /></Button></span>;
    } else {
      destino_mostrar = <span><Button color="link" id={"tooltip_ingreso_efectivo_" + ingreso.id_ingreso }   type="button"  className="btn btn-round btn-icon">  <i className="fas fa-money-bill-alt"></i></Button></span>;
    }
    if (ingreso.comprobante) { 
      comprobante_texto = 'Emitido';
      comprobante_mostrar = <span><Button color="link" id={"tooltip_ingreso_comprobante_" + ingreso.id_ingreso } type="button" alt={comprobante_texto}  className="btn btn-round btn-icon"> <i className="tim-icons  icon-paper"/></Button></span>;
      
    }else{
      comprobante_texto = 'Pendiente';
      comprobante_mostrar = <span><Button color="link" id={"tooltip_ingreso_comprobante_" + ingreso.id_ingreso } type="button" alt={comprobante_texto}  className="btn btn-round btn-icon"> <i className="tim-icons  icon-paper"/></Button></span>;
      
    }
    const edicion =  <span>
    <Button color="link"  type="button" onClick={() => handleDelete(ingreso.id_ingreso)}  ><i className="tim-icons  icon-trash-simple" /></Button>
    <Button color="link"  type="button" onClick={() => handleUpdate(ingreso)}  ><i className="tim-icons  icon-pencil" /></Button>

    </span>

    return (
    <tr key={ingreso.id_ingreso}>
      <td>  {moment(ingreso.fecha).format('DD/MM/YYYY')}  
  <i class="fas fa-circle-notch fa-spin"></i></td>
      <td>
        <p className="title">{ingreso.razon_social}</p>
        <p className="text-muted">  ({ ingreso.concepto })  </p>
      </td>
      <td> { ingreso.monto.toLocaleString('es-ar', { style: 'currency',  currency: 'ARS',  minimumFractionDigits: 2 }) } </td>
      <td> { ingreso.tipo }  </td>
      <td> { ingreso.estado } </td> 
      <td className="text-center" > {destino_mostrar} {}</td> 
      <td className="text-center"> { comprobante_mostrar } </td> 
      <td className="text-right"> { edicion }</td> 
    </tr>
    )
  }

render() {
    const { isLite } = this.props;
    if(isLite) {
      return this.renderLite();
    }
    else {
      return this.renderFull();
    }
  }
}

export default IngresoRow
import React from 'react';
import {  bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as actions from 'actions';
// reactstrap components
import {
  Label,
  FormGroup,
  Input,
  FormFeedback,
  Alert

} from "reactstrap";

import SelectInput from "components/Basics/SelectInput";


class NuevoIngreso extends React.Component {

   OPTIONS_TIPO_INGRESO = [{"value":"Web Adelanto","display":"Web Adelanto"},{"value":"Web Saldo","display":"Web Saldo"},{"value":"Mantenimiento Mensual" ,"display":"Mantenimiento Mensual"}];
   OPTIONS_ESTADO_INGRESO = [{"value":"Pendiente","display":"Pendiente"},{"value":"Cobrado","display":"Cobrado"}];
   OPTIONS_DESTINO_INGRESO = [{"value":"Caja","display":"Caja"},{"value":"Banco","display":"Banco"}];

   static propTypes = {
    createIngreso: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
  
    this.state = {
      monto: '',
      monto_error: undefined,
      concepto: '',
      concepto_error: undefined,
      form_sent: false,
      comprobante: 1,
      comprobanteck: false,
      selectedTipoIngreso: this.OPTIONS_TIPO_INGRESO[0].value,
      selectedEstadoIngreso: this.OPTIONS_ESTADO_INGRESO[0].value,
      selectedDestinoIngreso: this.OPTIONS_DESTINO_INGRESO[0].value,
      selectedCliente: this.props.clientes[0].id_cliente,
      
    };
  }

  
  componentWillMount() {
    //console.log('Component will mount!')
 }
  componentDidMount() {
      //console.log('Component did mount!')
  }


  shouldComponentUpdate(nextProps, nextState){
    //console.log('Should Component!' );
    //console.log( nextProps );
    return true;
   }
  componentWillUpdate(nextProps, nextState) {
     //console.log('Component will update!');
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

   // console.log(' Component did update!');
     
    if (this.props.clientes.length > 0 ) {
       if(this.props.clientes !== prevProps.clientes) {
        this.setState({selectedCliente: this.props.clientes[0].id_cliente});
      }
    }
  }
componentWillUnmount () {

  //console.log('Component will unmount!');
}

  handleInputChange = (source, e) => {
    this.setState({
      [source]: e.target.value,
      [source + '_error']: undefined
    });
  };

  handleValidation = () => {
    let valid = true;
    const {  monto, concepto} = this.state;
    
    if(concepto === ''){
      this.setState({concepto_error: "Campo Obligatorio"})
      valid =  false
    }
    if(monto === ''){
      this.setState({monto_error: "Campo Obligatorio"})
      valid  = false
    }else{
      if(isNaN(monto)) {
        this.setState({monto_error: "Solo Numeros"})
        valid  = false
      }
    }
    
    return valid
  }

  handleSubmit = e => {
    e.preventDefault();
    const {selectedTipoIngreso, selectedEstadoIngreso, selectedDestinoIngreso, selectedCliente, monto, concepto, comprobante} = this.state;
    const {pushNotification, toggle} = this.props;
    
    if (this.handleValidation()) {
      this.props.createIngreso({id_cliente: selectedCliente, monto, concepto, destino: selectedDestinoIngreso, estado: selectedEstadoIngreso, tipo: selectedTipoIngreso, comprobante});
      this.handleReset();
      var notification = {}
      notification.place = "tr"
      notification.message = "El ingreso fue registrado"
      notification.color = 2
      pushNotification(notification);
      toggle();
      this.setState({
        form_sent: true
      });
    }
  };

  handleReset = () => {
    this.setState({
      monto: '',
      concepto: '',
      monto_error: undefined,
      concepto_error: undefined,
      comprobante: 0,
      comprobanteck: false
    });
  };

  nuevo_ingreso = () => {
    this.setState({form_sent: false});
  }
  renderFormInputs = () =>  {
    const { selectedTipoIngreso, selectedEstadoIngreso, selectedDestinoIngreso, selectedCliente, monto, concepto, comprobante, comprobanteck} = this.state;
    const { clientes } = this.props;
    const opt_clientes = clientes.map(cliente => { 
    
    return {value: cliente.id_cliente, display: cliente.razon_social}});

      return (
        <div>
            <form onSubmit={ this.handleSubmit } noValidate>
            <div className="form-group">
            <SelectInput  
              title={'Cliente'}
              name={'id_cliente'}
              options = {opt_clientes} 
              placeholder = {'Selecione el Cliente'}
              value = {selectedCliente}
              handleChange = {(e) => this.handleInputChange('selectedCliente',e)}
              />
            </div>
            <div className="form-group">
              <SelectInput  
              title={'Tipo'}
              name={'tipo'}
              value={selectedTipoIngreso}
              options = {this.OPTIONS_TIPO_INGRESO} 
              placeholder = {'Selecione el Tipo'}
              handleChange = {(e) => this.handleInputChange('selectedTipoIngreso',e)}/>
            </div>
            <div className="form-group">
              <SelectInput  
              title={'Estado'}
              name={'estado'}
              options = {this.OPTIONS_ESTADO_INGRESO} 
              value = {selectedEstadoIngreso}
              placeholder = {'Selecione el Estado'}
              handleChange = {(e) => this.handleInputChange('selectedEstadoIngreso',e)}/>
            </div>
            <div className="form-group">
              <SelectInput  
              title={'Destino'}
              name={'destino'}
              value={selectedDestinoIngreso}
              options = {this.OPTIONS_DESTINO_INGRESO} 
              placeholder = {'Selecione el Destino'}
              handleChange = {(e) => this.handleInputChange('selectedDestinoIngreso',e)}/>
            </div>
            <div className="form-group">
               <label htmlFor={'monto'}> Monto </label>
               <FormGroup>
                <Input
                invalid={this.state.monto_error !== undefined}
                className="form-control"
                name="monto"
                value={ monto }
                onChange = {(e) => this.handleInputChange('monto',e)}/>
                <FormFeedback>{this.state.monto_error}</FormFeedback>
                </FormGroup>
            </div>
            <div className="form-group">
               <label htmlFor={'concepto'}> Concepto </label>
               <FormGroup>
                 <Input
                invalid={this.state.concepto_error !== undefined}
                value={concepto}
               className="form-control"
               name={'concepto'}
               onChange = {(e) => this.handleInputChange('concepto',e)}
               /><FormFeedback>{this.state.concepto_error}</FormFeedback>
               </FormGroup>
            </div>
            <div className="form-group">
            <FormGroup check>
              <Label check> Comprobante
                <Input defaultChecked={comprobanteck} value={comprobante} type="checkbox" name={'comprobante'} onChange = {(e) => this.handleInputChange('comprobante',e)} />
                <span className="form-check-sign">
                  <span className="check" />
                </span>
              </Label>
            </FormGroup>
            </div>
            <div className="form-group">
              <button type="button" className="btn btn-warning" onClick={ this.handleReset }>
                Resetear
              </button>
              <button type="submit" className="btn btn-primary">Agregar Ingreso</button>
            </div>
          </form>
        </div>
      );

  }

  renderFormSuccess = () =>  {
    const { toggle } = this.props;
    return (
      <div className="formSucces">
            <Alert color="success">
              Listo el formulario ha sido cargado! <br/>
              <button type="button" className="btn" onClick={toggle}> Cerrar </button>
              <button type="button" className="btn" onClick={this.nuevo_ingreso}> Nuevo Ingreso</button>
            </Alert>
      </div>
    )
  }
  
  render() {
    if (this.state.form_sent) {
      return this.renderFormSuccess();
    }else{
      return this.renderFormInputs();
    }
  }

}

const mapStateToProps = state => {
  return { };
};


const mapDispatchToProps = dispatch => bindActionCreators({
  pushNotification: actions.pushNotification
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NuevoIngreso);


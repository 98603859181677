import React from 'react';
import {  bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as actions from 'actions';
import moment from 'moment';
// reactstrap components
import {
  Label,
  FormGroup,
  Input
} from "reactstrap";

import SelectInput from "components/Basics/SelectInput";


class EditarIngreso extends React.Component {

   OPTIONS_TIPO_INGRESO = [{"value":"Web Adelanto","display":"Web Adelanto"},{"value":"Web Saldo","display":"Web Saldo"},{"value":"Mantenimiento Mensual" ,"display":"Mantenimiento Mensual"}];
   OPTIONS_ESTADO_INGRESO = [{"value":"Pendiente","display":"Pendiente"},{"value":"Cobrado","display":"Cobrado"}];
   OPTIONS_DESTINO_INGRESO = [{"value":"Caja","display":"Caja"},{"value":"Banco","display":"Banco"}];

   static propTypes = {
    updateIngreso: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
  
    this.state = {
      id_ingreso: this.props.ingreso_a_editar.id_ingreso,
      monto: this.props.ingreso_a_editar.monto,
      fecha: moment(this.props.ingreso_a_editar.fecha).format('YYYY-MM-DD') ,
      concepto: this.props.ingreso_a_editar.concepto,
      comprobante: this.props.ingreso_a_editar.comprobante,
      comprobanteck: false,
      selectedTipoIngreso: this.props.ingreso_a_editar.tipo,
      selectedEstadoIngreso: this.props.ingreso_a_editar.estado,
      selectedDestinoIngreso: this.props.ingreso_a_editar.destino,
      selectedCliente: this.props.ingreso_a_editar.id_cliente
    };
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {

    if (this.props.clientes.length > 0 ) {
       if(this.props.clientes !== prevProps.clientes) {
        this.setState({selectedCliente: this.props.ingreso_a_editar.id_cliente});

      }
    }
  }

  handleInputChangeFecha = (e) => {
    this.setState({
      fecha:  moment(e.target.value).format('YYYY-MM-DD'),
    });
  };

  handleInputChange = (source, e) => {
    this.setState({
      [source]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const {id_ingreso, selectedTipoIngreso, selectedEstadoIngreso, selectedDestinoIngreso, selectedCliente, monto, concepto, comprobante,fecha} = this.state;
    const {pushNotification, toggle} = this.props;
    if (selectedCliente && monto) {
      this.props.updateIngreso({id_ingreso: id_ingreso,fecha: fecha,id_cliente: selectedCliente, monto, concepto, destino: selectedDestinoIngreso, estado: selectedEstadoIngreso, tipo: selectedTipoIngreso, comprobante});
      this.handleReset();
      var notification = {}
      notification.place = "tr"
      notification.message = "El ingreso fue modificado"
      notification.color = 2
      pushNotification(notification);
      toggle();
      this.setState({
        form_sent: true
      });
    }
  };

  handleReset = () => {
    this.setState({
      monto: '',
      concepto: '',
      comprobante: 0,
      comprobanteck: false
    });
  };

  render() {
    const { selectedTipoIngreso, selectedEstadoIngreso, selectedDestinoIngreso, selectedCliente, monto, concepto, comprobante, comprobanteck, fecha} = this.state;
    const { clientes } = this.props;
    const opt_clientes = clientes.map(cliente => { 
      return {value: cliente.id_cliente, display: cliente.razon_social}});

    return (
      <div>
          <form onSubmit={ this.handleSubmit }>
          <div className="form-group">
          <FormGroup>
            <Label for="exampleDate">Fecha</Label>
            <Input
              type="date"
              name={'fecha'}
              value = {fecha}
              onChange = {(e) => this.handleInputChangeFecha(e)}
            />
          </FormGroup>
          </div>
          <div className="form-group">
          <SelectInput  
            title={'Cliente'}
            name={'id_cliente'}
            options = {opt_clientes} 
            placeholder = {'Selecione el Cliente'}
            value = {selectedCliente}
            handleChange = {(e) => this.handleInputChange('selectedCliente',e)}/>
          </div>
          <div className="form-group">
            <SelectInput  
            title={'Tipo'}
            name={'tipo'}
            value={selectedTipoIngreso}
            options = {this.OPTIONS_TIPO_INGRESO} 
            placeholder = {'Selecione el Tipo'}
            handleChange = {(e) => this.handleInputChange('selectedTipoIngreso',e)}/>
          </div>
          <div className="form-group">
            <SelectInput  
            title={'Estado'}
            name={'estado'}
            options = {this.OPTIONS_ESTADO_INGRESO} 
            value = {selectedEstadoIngreso}
            placeholder = {'Selecione el Estado'}
            handleChange = {(e) => this.handleInputChange('selectedEstadoIngreso',e)}/>
          </div>
          <div className="form-group">
            <SelectInput  
            title={'Destino'}
            name={'destino'}
            value={selectedDestinoIngreso}
            options = {this.OPTIONS_DESTINO_INGRESO} 
            placeholder = {'Selecione el Destino'}
            handleChange = {(e) => this.handleInputChange('selectedDestinoIngreso',e)}/>
          </div>
          <div className="form-group">
             <label htmlFor={'monto'}> Monto </label>
              <Input
              className="form-control"
              name="monto"
              value={ monto }
              onChange = {(e) => this.handleInputChange('monto',e)}/>
          </div>
          <div className="form-group">
             <label htmlFor={'concepto'}> Concepto </label>
              <Input
              value={concepto}
             className="form-control"
             name={'concepto'}
             onChange = {(e) => this.handleInputChange('concepto',e)}/>
          </div>
          <div className="form-group">
          <FormGroup check>
            <Label check> Comprobante 
              <Input defaultChecked={comprobanteck} value={comprobante} type="checkbox" name={'comprobante'} onChange = {(e) => this.handleInputChange('comprobante',e)} />
              <span className="form-check-sign">
                <span className="check" />
              </span>
            </Label>
          </FormGroup>
          </div>
          <div className="form-group">
            <button type="submit" className="btn btn-primary">Guardar</button>
          </div>
        </form>
      </div>
    );
  }
}


const mapStateToProps = state => {
  return { };
};


const mapDispatchToProps = dispatch => bindActionCreators({
  pushNotification: actions.pushNotification
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditarIngreso);


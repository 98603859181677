
import { FETCH_CLIENTE } from '../actions/types';
import { orderBy } from "lodash";

export default function clienteReducer(state = [], action) {
  switch (action.type) {
    case FETCH_CLIENTE:
      const clientes = orderBy(action.clientes,"razon_social",  "asc" );
      return clientes;
    default:
      return state;
  }
}
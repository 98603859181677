import React from "react";
import {  bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from 'actions';

import { getClientes  } from 'selectors/clientesSelector'
import { getIngresos  } from 'selectors/ingresosSelector'

//Components
import IngresosList from "components/IngresosList"
import IngresoNewForm from 'components/IngresoNewForm';
import IngresoEditForm from 'components/IngresoEditForm';

// reactstrap components
import {
  Row,
  Col,
  Button,
  Modal, ModalHeader, ModalBody 
} from "reactstrap";


class Ingresos extends React.Component {
 
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      modal: false,
      modal_edit: false,
      color: "navbar-transparent",
      size: 2
    };

    this.toggle = this.toggle.bind(this)
    this.toggle_edit = this.toggle_edit.bind(this)
  }

  componentDidMount() {
    const {fetchAllIngresos, fetchAllClientes} = this.props
    fetchAllIngresos();
    fetchAllClientes();
  }
  
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }  
  toggle_edit() {
    this.setState(prevState => ({
      modal_edit: !prevState.modal_edit
    }));
  } 
  handleDelete = (id) => {
    const {onDeleteIngreso, pushNotification} = this.props;
    onDeleteIngreso(id)
    var notification = {}
    notification.place = "tr"
    notification.message = "El ingreso fue borrado"
    notification.color = 2
    pushNotification(notification);
  }
  handleUpdate = (ingreso) => {
   this.setState({ingreso_a_editar: ingreso})
   this.toggle_edit();
  }

  

  render() {
    const {clientes, ingresos, onDeleteIngreso, createIngreso,updateIngreso} = this.props;

    const { size, ingreso_a_editar } = this.state;
    return (
      <>
        <div className="content">
          <Row>
            <Col>
               <Button data-toggle="modal" id="search-button" onClick={this.toggle}>
                  <i className="tim-icons icon-simple-add" />
                  <span className="d-lg-none d-md-block">Nuevo Ingreso</span>
                </Button>
              </Col>
          </Row>
          <Row>
            <Col lg="12">
              <IngresosList ingresos={ingresos} handleUpdate={this.handleUpdate} onDeleteIngreso={ onDeleteIngreso } handleDelete={ this.handleDelete} size={ size }  tableTitle={'Ingresos'} tableSubtitle={'Listado de Ingresos'}/>
            </Col>
          </Row>
        </div>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} >
          <ModalHeader toggle={this.toggle}>Nuevo Ingreso</ModalHeader>
          <ModalBody>
              <IngresoNewForm clientes={clientes}  createIngreso={ createIngreso } toggle={this.toggle} notify={this.notify}/> 
          </ModalBody>
        </Modal>

        <Modal isOpen={this.state.modal_edit} toggle={this.toggle_edit} className={this.props.className} >
          <ModalHeader toggle={this.toggle_edit}>Editar Ingreso</ModalHeader>
          <ModalBody>
            <IngresoEditForm  ingreso_a_editar={ingreso_a_editar}  clientes={clientes}  updateIngreso={updateIngreso}  toggle={this.toggle_edit} notify={this.notify}/> 
          </ModalBody>
        </Modal>
      </>
    );
  }
}


const mapStateToProps = state => {
  return {
    clientes:  getClientes(state),
    ingresos: getIngresos(state)
  };
};


const mapDispatchToProps = dispatch => bindActionCreators({
  createIngreso: actions.createIngreso,
  updateIngreso: actions.updateIngreso,
  fetchAllIngresos: actions.fetchAllIngresos,
  fetchAllClientes: actions.fetchAllClientes,
  onDeleteIngreso: actions.onDeleteIngreso,
  pushNotification: actions.pushNotification
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Ingresos);


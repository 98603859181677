
import { ADD_INGRESO, DELETE_INGRESO, FETCH_INGRESO, UPDATE_INGRESO } from '../actions/types';
import { orderBy } from "lodash";

export default function ingresoReducer(state = [], action) {
  switch (action.type) {
    case ADD_INGRESO:
      const newstate = orderBy([...state, action.payload],"fecha",  "desc" );
      return newstate;
    case DELETE_INGRESO:
      return state.filter(ingreso => ingreso.id_ingreso !== action.payload.id);
    case FETCH_INGRESO:
      //return action.ingresos;  
      const newstate2 = orderBy(action.ingresos,"fecha",  "desc" );
      return newstate2
  case UPDATE_INGRESO:
    let item = action.payload;
    let items = [...state];
    let foundIndex = items.findIndex(x => x.id_ingreso === item.id_ingreso);
    items[foundIndex] = item;
    items = orderBy(items,"fecha",  "desc" );
    return items; 
    default:
      return state;
  }
}
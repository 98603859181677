import React from "react";
import moment from 'moment';
import {  bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from 'actions';

import { getClientes  } from 'selectors/clientesSelector'
import { getIngresos  } from 'selectors/ingresosSelector'


//Components
import IngresosList from "components/IngresosList"
// reactstrap components
import {
  Row,
  Col
} from "reactstrap";

class Dashboard extends React.Component {

  componentDidMount() {
    
    const {fetchAllIngresosMes, fetchAllClientes} = this.props

    const monthActual = moment().format('MM')
    const yearActual = moment().format('YYYY')
    fetchAllIngresosMes(monthActual,yearActual);
    fetchAllClientes();
  }
 
 
  render() {


    const {ingresos, onDeleteIngreso} = this.props;
    const mesActual = moment().format('MM/YY')
    return (
      <>
        <div className="content">
          <Row>
            <Col lg="6" md="6">
              <IngresosList ingresos={ingresos} onDeleteIngreso={ onDeleteIngreso } isLite={true} tableTitle={'Ingresos'} tableSubtitle={'Ingresos ' + mesActual}/>
            </Col>
            <Col lg="6" md="6">
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    clientes:  getClientes(state),
    ingresos: getIngresos(state)
  };
};


const mapDispatchToProps = dispatch => bindActionCreators({
  fetchAllIngresosMes: actions.fetchAllIngresosMes,
  fetchAllClientes: actions.fetchAllClientes,
  onDeleteIngreso: actions.onDeleteIngreso
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);




export function  getClientes  (state)  {

  return state.clientes.map( cliente => { 
    return {
        
          razon_social: cliente.razon_social,
          id_cliente: cliente.id_cliente
       
    }
  })

}

export function  getClienteById  (id, clientes)  {


  const ret = clientes.find( cliente => {
    return cliente.id_cliente === id  
  })
  if (ret !== undefined) {
    return ret.razon_social;
  }

}


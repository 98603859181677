import { getClienteById } from 'selectors/clientesSelector'


export function  getIngresos  (state)  {


  return state.ingresos.map( ingreso => { 
    return {
       ...ingreso, ...{
          razon_social: getClienteById(ingreso.id_cliente, state.clientes)
       }
    }
  })

}


import { ADD_INGRESO, UPDATE_INGRESO, DELETE_INGRESO, FETCH_INGRESO, FETCH_CLIENTE, PUSH_NOTIFICATION } from './types';
import axios from 'axios';
require('dotenv').config()

let apiUrl = ''
if (process.env.NODE_ENV === 'production' ) {
 apiUrl = 'https://api-gallagher-gestion.herokuapp.com'
} else {
 apiUrl = 'http://localhost:8000'
}

export const updateIngreso = ({id_ingreso, id_cliente, monto, concepto, destino, estado, tipo , comprobante, fecha}) => {
  return (dispatch) => {
    return axios.put(`${apiUrl}/ingresos/actualizar/${id_ingreso}`,{id_cliente, monto, concepto , destino, estado, tipo , comprobante,fecha})
      .then(response => {
        dispatch(updateIngresoSuccess(response.data))
      })
      .catch(error => {
        throw(error);
      });
  };
};


export const createIngreso = ({id_cliente, monto, concepto, destino, estado, tipo , comprobante}) => {
  return (dispatch) => {
    return axios.post(`${apiUrl}/ingresos/insertar`,{id_cliente, monto, concepto , destino, estado, tipo , comprobante})
      .then(response => {
        dispatch(createIngresoSuccess(response.data))
      })
      .catch(error => {
        throw(error);
      });
  };
};

export const updateIngresoSuccess =  (data) => {
  return {
    type: UPDATE_INGRESO,
    payload: {
      id_cliente: data.id_cliente,
      id_ingreso: data.id_ingreso,
      monto: data.monto,
      concepto: data.concepto,
      fecha: data.fecha,
      comprobante: data.comprobante,
      tipo: data.tipo,
      estado: data.estado,
      destino: data.destino
    }
  }
};
export const createIngresoSuccess =  (data) => {
  return {
    type: ADD_INGRESO,
    payload: {
      id_cliente: data.id_cliente,
      id_ingreso: data.id_ingreso,
      monto: data.monto,
      concepto: data.concepto,
      fecha: data.fecha,
      comprobante: data.comprobante,
      tipo: data.tipo,
      estado: data.estado,
      destino: data.destino
    }
  }
};

export const pushNotification =  (data) => {
  console.log(data);
  return {
    type: PUSH_NOTIFICATION,
    payload: {
      message: data
    }
  }
};

export const deleteIngresoSuccess = id => {
  return {
    type: DELETE_INGRESO,
    payload: {
      id
    }
  }
}

export const onDeleteIngreso = id => {
  return (dispatch) => {
    return axios.get(`${apiUrl}/ingresos/borrar/${id}`)
      .then(response => {
        dispatch(deleteIngresoSuccess(response.data))
        })
      .catch(error => {
        throw(error);
      });
  };
};

export const fetchIngresos = (ingresos) => {
  return {
    type: FETCH_INGRESO,
    ingresos
  }
};

export const fetchAllIngresosMes = (mes, year) => {
  return (dispatch) => {
    return axios.get(`${apiUrl}/ingresos/fecha/${year}/${mes}`)
      .then(response => {
        dispatch(fetchIngresos(response.data))
      })
      .catch(error => {
        throw(error);
      });
  };
};

export const fetchAllIngresos = () => {
  return (dispatch) => {
    return axios.get(`${apiUrl}/ingresos/`)
      .then(response => {
        dispatch(fetchIngresos(response.data))
      
      })
      .catch(error => {
        throw(error);
      });
  };
};

export const fetchClientes = (clientes) => {
  return {
    type: FETCH_CLIENTE,
    clientes
  }
};

export const fetchAllClientes = () => {
  return (dispatch) => {
    return axios.get(`${apiUrl}/clientes/`)
      .then(response => {
        dispatch(fetchClientes(response.data))
      })
      .catch(error => {
        throw(error);
      });
  };
};

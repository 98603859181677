import React  from 'react';

const Select = (props) => {
  return(
      <div className="form-group">
          <label htmlFor={props.name}> {props.title} </label>
          <select  className="form-control"
            name={props.name}
            value={props.value}
            onChange={props.handleChange}
            >
            <option value="" disabled>{props.placeholder}</option>
            {props.options.map(option => {
              return (
                <option
                key={option.value}
                value={option.value}
                label={option.display}>{option.display}
                </option>
              );
            })}
          </select>
    </div>)
}

export default Select;
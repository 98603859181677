
import { PUSH_NOTIFICATION } from '../actions/types';

export default function noticationReducer(state = [], action) {
  switch (action.type) {
    case PUSH_NOTIFICATION:
      const new_state = [...state, action.payload.message]
      console.log(action.payload.message)
      return new_state;
    default:
      return state;
  }
}
import React from 'react';
import {  bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import NotificationAlert from "react-notification-alert";


class NotificationHandler extends React.Component {
  

  componentDidUpdate() {
    this.notify(this.props.notifications[0].place);
  }

  notify = place  => {
    var color = Math.floor(Math.random() * 5 + 1);
    let len=this.props.notifications.length;
    console.log("LEN DEL ARRAY " + len);
    color = this.props.notifications[0].color;
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: this.props.notifications[len-1].place,
      message: (
        <div>
          <div>
           {this.props.notifications[len-1].message}
          </div>
        </div>
      ),
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 4
    };
    this.refs.notificationAlert.notificationAlert(options);
  };



render() {
  return (
    <div className="react-notification-alert-container">
      <NotificationAlert ref="notificationAlert" />
    </div>
    );
  }
}


const mapStateToProps = state => {
  return {
    notifications: state.notifications
   };
};

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationHandler);

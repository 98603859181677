import React from 'react';
//import IngresoRow from 'components/IngresoRow/index copy.js';
import IngresoRow from 'components/IngresoRow';
import PaginatedTable from 'components/Basics/PaginatedTable';

class IngresosList extends React.Component {
  
render() {

const { ingresos , onDeleteIngreso, handleDelete, isLite, tableTitle, tableSubtitle, handleUpdate} = this.props;

var tableHeader = '';

    if (isLite) {
      tableHeader = ['Cliente', 'Monto', ''];
    }else{
      tableHeader = ['Fecha', 'Cliente',  'Monto', 'Tipo' , 'Estado', 'Destino', 'Comprobante', 'Acción'];
    }
    
    if(!ingresos.length) {
      return (
        <div>
          No Hay Ingresos :(
        </div>
      )
    }
return (
  <div>
      <PaginatedTable 
      isLite={isLite}
      registros={ingresos.map((ingreso, index) => {
        return <IngresoRow ingreso={ ingreso } onDelete={ onDeleteIngreso }  handleUpdate={handleUpdate} handleDelete={handleDelete}  key={ index } isLite={ isLite }/>
      })}  
      currentPage={1}
      regsPerPage= {10}
      upperPageBound={20}
      lowerPageBound= {0}
      isPrevBtnActive= {'disabled'}
      isNextBtnActive= {''}
      pageBound= {20} 
      tableTitle={tableTitle}
      tableSubtitle={tableSubtitle}
      tableHeader={tableHeader}/>  
    </div>
  );
}
}




export default IngresosList